<template>
  <div v-show="applications">
    <el-dialog
      :visible.sync="applications"
      width="530px"
      :before-close="() => {}"
    >
      <div
        class="dialog-delete"
        @click="
          () => {
            this.$emit('update:applications', false);
          }
        "
      >
        <i class="el-icon-close"></i>
      </div>
      <div class="title">{{ activityName }}</div>
      <div class="time" v-if="type == 'LIVE_BROADCAST'">
        开课时间： {{ courseStartTime }}
      </div>
      <div class="time" v-else>报名截止时间：{{ endRegisterTime }}</div>
      <p class="a__download">下载报名表模板</p>
      <el-button
        class="a__btn__download"
        @click="downloadTemplate"
        :loading="elloading"
      >
        <img src="@/assets/images/curriculum/download-1.png" alt="" />
        <span>易杰代报名模板.xlsx</span>
      </el-button>
      <div class="a__box">
        <div class="flex conet j-w">
          <div class="flex conet a__box__title">
            <span>上传报名表</span>
            <span>支持多次上传</span>
          </div>
          <div v-if="list.length > 0 && !file">
            <el-upload
              ref="upload"
              :limit="1"
              :http-request="ImgUploadSectionFile"
              :with-credentials="true"
              :auto-upload="true"
              accept=".xlsx"
              action=""
              list-type="list"
              :show-file-list="false"
              :file-list="fileList"
            >
              <div class="a__box__btn">上传</div>
            </el-upload>
          </div>
          <div v-if="file && list.length > 0">
            <div class="a__box__btn" @click="UploadSectionFile">上传</div>
          </div>
        </div>
        <template v-if="list.length > 0 || file">
          <div class="c__list__box">
            <div
              class="c__list__flex"
              v-for="(itme, index) in list"
              :key="index"
            >
              <div class="flex j-w conet">
                <p>{{ index + 1 }}.{{ itme.fileName }}</p>
                <span style="color: #00bd92; font-size: 12px">已提交</span>
              </div>
              <div class="flex j-w c__list__btext">
                <span
                  >上传于
                  {{
                    itme.createTime
                      | timeFormatter(
                        "{year}年{month}月{day}日 {hour}:{minute}"
                      )
                  }}</span
                >
              </div>
              <div class="flex conet j-w">
                <div class="c__list__btext" style="color: #ff5102">
                  <span>成功人数：{{ itme.success }}，</span>
                  <span>失败人数：{{ itme.failed }}，</span>
                  <span>未审核人数：{{ itme.submitted }}</span>
                </div>
                <div
                  @click="examine(itme)"
                  style="color: #5090ff; font-size: 12px; cursor: pointer"
                >
                  查看
                </div>
              </div>
            </div>
            <template v-if="list.length > 0 && !file">
              <el-upload
                ref="upload"
                :limit="1"
                :http-request="ImgUploadSectionFile"
                :with-credentials="true"
                :auto-upload="true"
                accept=".xlsx"
                action=""
                list-type="list"
                :show-file-list="false"
                :file-list="fileList"
              >
                <div class="flex conet j-c flexUpload">
                  <img src="@/assets/images/curriculum/sign-add.png" alt="" />
                  继续添加
                </div>
              </el-upload>
            </template>
            <!-- 单个的 -->
            <div class="c__list__flex" v-if="file">
              <p>{{ list.length + 1 }}.{{ file.name }}</p>
              <div class="flex j-w c__list__btext">
                <span
                  >上传于{{
                    new Date().getTime()
                      | timeFormatter(
                        "{year}年{month}月{day}日 {hour}:{minute}"
                      )
                  }}</span
                >
                <div class="flex conet">
                  <span class="success">待提交</span>
                  <span class="success" @click="deleteFile">取消</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <el-upload
            ref="upload"
            :limit="1"
            :http-request="ImgUploadSectionFile"
            :with-credentials="true"
            :auto-upload="true"
            accept=".xlsx"
            action=""
            list-type="list"
            :show-file-list="false"
            :file-list="fileList"
          >
            <div class="con j-c conet p__box">
              <img src="@/assets/images/curriculum/download_555.png" alt="" />
              <div class="p__box__btn">上传</div>
            </div>
          </el-upload>
        </template>
      </div>
      <!-- 输入框 -->
      <div class="flex conet f__box">
        <span>联系人：</span>
        <input
          type="text"
          v-model="name"
          maxlength="6"
          placeholder="请输入联系人"
        />
      </div>
      <div class="flex conet f__box">
        <span>联系电话：</span>
        <input
          type="text"
          maxlength="11"
          oninput="value=value.replace(/[^0-9]/g,'')"
          v-model="phone"
          placeholder="请输入联系电话"
        />
      </div>
      <div v-if="list.length > 0 || file" class="warm__box">
        <p>温馨提醒：请耐心等待，信息有待审核，审核成功后即可报名成功。</p>
        <div class="con j-c conet">
          <el-button @click="uploading" :loading="loading">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Utils from '@/utils';
import axios from 'axios';
import { mapGetters } from 'vuex';
import {
  substituteRegistrationTemplate,
  courseTeachesr,
  courseTeacherList
} from '@/api/api';
import {
  checkPhone,
  checkName,
  checkExpression,
  timeFormatter
} from '@/utils/verify';
export default {
  name: 'PopupApplications',
  filters: { timeFormatter },
  props: {
    applications: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    endRegisterTime: {
      type: String,
      default: ''
    },
    courseStartTime: {
      type: String,
      default: ''
    },
    activityName: {
      type: String,
      default: ''
    },
    activityId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['token', 'userPhone', 'username'])
  },
  data() {
    return {
      list: [],
      fileList: [],
      name: '',
      phone: '',
      file: '',
      loading: false,
      elloading: false
    };
  },
  watch: {
    name(val) {
      setTimeout(() => {
        if (checkName(val)) {
          this.name = val.substring(0, val.length - 1);
        }
        if (checkExpression(val)) {
          this.name = '';
        }
      }, 100);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.name = this.username;
      this.phone = this.userPhone;
      courseTeacherList({
        activityId: this.activityId
      }).then((r) => {
        if (r.code == 200) {
          this.list = r.data;
        }
      });
    },
    UploadSectionFile() {
      Utils.msgWarning(`请先点击下方确认按钮，提交后再继续上传`, 4000);
    },
    uploading() {
      const _this = this;
      if (_this.name == '') {
        Utils.msgWarning(`联系人不能为空`);
        return;
      }
      if (!checkPhone(_this.phone)) {
        Utils.msgWarning(`联系方式不正确`);
        return;
      }
      if (_this.file == '') {
        Utils.msgWarning(`请上传文件`);
        return;
      }
      _this.loading = true;
      const config = {
        headers: {
          'Content-Type':
            'multipart/form-data;boundary=' + new Date().getTime(),
          Authorization: 'hys' + _this.token()
        }
      };
      const formData = new FormData();
      const obj = {
        multipartFile: _this.file,
        activityId: _this.activityId,
        agentName: _this.name,
        agentPhone: _this.phone,
        fileName: _this.file.name
      };
      for (const i in obj) {
        formData.append(i, obj[i]);
      }
      axios
        .post('/api' + courseTeachesr(), formData, config)
        .then(function (response) {
          const { data } = response;
          switch (data.code) {
            case 200:
              _this.periodPopupFile(
                '提交成功',
                '请耐心等待，信息有待审核，审核成功后即可报名成功。'
              );
              break;
            case 4113:
              _this.periodPopupFile('重复提交！', '报名模板重复提交');
              break;
            default:
              _this.periodPopupFile(
                '提交失败',
                '报名表格式有误，请使用正确的报名模板。'
              );
              break;
          }
        })
        .catch(function (error) {
          _this.loading = false;
          console.log(error);
        });
    },
    periodPopupFile(headline, title) {
      this.$periodPopup({
        dialog: false,
        headline: headline,
        title: title
      }).then(() => {
        this.loading = false;
        this.init();
        this.file = '';
        this.fileList = [];
      });
    },
    downloadTemplate() {
      this.elloading = true;
      axios({
        method: 'get',
        url: '/api' + substituteRegistrationTemplate(),
        responseType: 'blob',
        headers: { Authorization: 'hys' + this.token() },
        params: {}
      })
        .then((res) => {
          this.elloading = false;
          var blob = new Blob([res.data]);
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '易杰代报名模板.xlsx';
          downloadElement.click();
        })
        .catch(() => {
          this.elloading = false;
        });
    },
    ImgUploadSectionFile(param) {
      const { file } = param;
      this.file = file;
    },
    // 查看
    examine(itme) {
      this.$router.push({
        path: '/generationApply',
        query: {
          activityId: this.activityId,
          fileId: itme.fileUserId,
          fileName: itme.fileName
        }
      });
    },
    deleteFile() {
      this.$trainPopup({
        title: '确认要取消本次上传报名人员？',
        leftBtn: '取消',
        rightBtn: '确认'
      }).then((res) => {
        this.file = '';
        this.fileList = [];
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~./index";
</style>

