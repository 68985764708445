<template>
  <div class="container courseDetails">
    <div class="container-fluid">
      <div class="container-bor clear">
        <div class="fl bg-img">
          <template v-if="!courseVideoUrl">
            <img :src="objActivity.imgUrl" alt="" />
          </template>
          <template v-if="courseVideoUrl">
            <Xgplayer
              :imageUrl="objActivity.imgUrl"
              :courseVideoUrl="courseVideoUrl"
              :activityCourseId="activityCourseId"
              :disableTitle="disableTitle"
              :proTime="proTime"
              :proActivityCourseId="proActivityCourseId"
              :disableProgress="disableProgress"
              @xgplayerIncident="xgplayerIncident"
            >
            </Xgplayer>
          </template>
        </div>
        <div class="fl rig">
          <div class="clear" style="marginbottom: 8px">
            <div class="fl h1">{{ objActivity.activityName }}</div>
            <div class="fl r-onsite">
              {{ objActivity.tag | course_classification }}
            </div>
          </div>
          <div class="flex j-w conet">
            <el-rate
              v-model="value"
              disabled
              show-score
              :colors="colors"
              score-template="{value} 分"
              text-color="#FF5102"
            >
            </el-rate>
            <div class="r-time">
              <span>{{ objActivity.studyHour }}</span
              >学时
            </div>
          </div>
          <template>
            <div style="margin-top: 15px">
              <!-- 精品课 -->
              <template v-if="objActivity.tag == 'UPLOAD_VIDEO'">
                <div :class="['time']">
                  <p>
                    报名截止时间：{{
                      objActivity.endRegisterTime
                        | timeFormatter(
                          "{year}年{month}月{day}日 {hour}:{minute}:{second}"
                        )
                    }}
                  </p>
                  <p v-if="objActivity.cancelRegisterEndTime != null">
                    取消报名截止时间：{{
                      objActivity.cancelRegisterEndTime
                        | timeFormatter(
                          "{year}年{month}月{day}日 {hour}:{minute}:{second}"
                        )
                    }}
                  </p>
                </div>
              </template>
              <!-- 直播课 -->
              <template v-else-if="objActivity.tag == 'LIVE_BROADCAST'">
                <div class="time">
                  <p>
                    <span
                      >报名截止时间：{{
                        objActivity.endRegisterTime
                          | timeFormatter(
                            "{year}年{month}月{day}日 {hour}:{minute}:{second}"
                          )
                      }}
                    </span>
                    <span
                      v-if="objActivity.cancelRegisterEndTime != null"
                      style="margin-left: 15px; color: #e4393c"
                      >(
                      {{
                        objActivity.cancelRegisterEndTime
                          | timeFormatter(
                            "{month}月{day}日 {hour}:{minute}:{second}"
                          )
                      }}
                      前可取消报名 )</span
                    >
                  </p>
                  <p>直播开课时间：{{ courseStartTimeAdnCourseEndTime }}</p>
                </div>
              </template>
              <!-- 面授课 -->
              <template v-else-if="objActivity.tag == 'ONSITE_TEACHING'">
                <div :class="['time']">
                  <p>
                    报名截止时间：{{
                      objActivity.endRegisterTime
                        | timeFormatter(
                          "{year}年{month}月{day}日 {hour}:{minute}:{second}"
                        )
                    }}
                  </p>
                  <p v-if="objActivity.cancelRegisterEndTime != null">
                    取消报名截止时间：{{
                      objActivity.cancelRegisterEndTime
                        | timeFormatter(
                          "{year}年{month}月{day}日 {hour}:{minute}:{second}"
                        )
                    }}
                  </p>
                  <p>培训时间：{{ courseStartmathMinEndTime }}</p>
                  <p class="flex j-w conet">
                    <span class="addess"
                      >培训地点：{{ objActivity.activityPlace }}</span
                    >
                    <span
                      v-if="
                        objActivity.activityPlaceLatitude &&
                        objActivity.activityPlaceLongitude
                      "
                      :class="[
                        objActivity.wetherToRegister ? 'mapLogin' : 'map',
                      ]"
                      @click.stop="
                        () => {
                          mapVisible = true;
                        }
                      "
                      >地图</span
                    >
                  </p>
                </div>
              </template>
            </div>
            <div
              :class="['people', objActivity.wetherToRegister ? 'active' : '']"
            >
              <div
                v-if="
                  !objActivity.isOpen &&
                  objActivity.tag != 'ONSITE_TEACHING' &&
                  !objActivity.isRegisterByRoster
                "
              >
                {{ objActivity.holdUpSeats }}人已报名，还剩{{
                  objActivity.seats - objActivity.holdUpSeats
                }}个名额
              </div>
              <div
                v-else-if="
                  objActivity.isOpen ||
                  (objActivity.tag == 'ONSITE_TEACHING' &&
                    !objActivity.isRegisterByRoster)
                "
              >
                {{ objActivity.holdUpSeats }} 人已报名
              </div>
              <div v-else-if="objActivity.isRegisterByRoster">
                {{ objActivity.holdUpSeats }} 人已报名
              </div>
            </div>
          </template>
          <div :class="['clear', 'pot']">
            <template v-if="!isStartRegister && !isEndRegisterTime">
              <template v-if="objActivity.isOpen">
                <div
                  :class="[
                    'fl',
                    'btnApply',
                    objActivity.wetherToRegister ? 'potActive' : '',
                  ]"
                  @click="signUpImmediatelyIncident"
                >
                  <el-button>
                    <div>
                      {{
                        objActivity.wetherToRegister ? "取消报名" : "立即报名"
                      }}
                    </div>
                  </el-button>
                </div>
                <div
                  v-if="objActivity.tag == 'ONSITE_TEACHING'"
                  @click="applicationsUpImmediatelyIncident"
                  :class="['fl', 'applications']"
                >
                  <el-button>
                    <div>团体报名</div>
                  </el-button>
                </div>
              </template>
              <template v-else>
                <template v-if="objActivity.isRegisterByRoster">
                  <div
                    :class="[
                      'fl',
                      'btnApply',
                      objActivity.wetherToRegister ? 'potActive' : '',
                    ]"
                    @click="signUpImmediatelyIncident"
                  >
                    <el-button>
                      <div>
                        {{
                          objActivity.wetherToRegister ? "取消报名" : "立即报名"
                        }}
                      </div>
                    </el-button>
                  </div>
                  <div
                    v-if="objActivity.tag == 'ONSITE_TEACHING'"
                    @click="applicationsUpImmediatelyIncident"
                    :class="['fl', 'applications']"
                  >
                    <el-button>
                      <div>团体报名</div>
                    </el-button>
                  </div>
                </template>
                <template v-else>
                  <template v-if="objActivity.seats > objActivity.holdUpSeats">
                    <div
                      :class="[
                        'fl',
                        'btnApply',
                        objActivity.wetherToRegister ? 'potActive' : '',
                      ]"
                      @click="signUpImmediatelyIncident"
                    >
                      <el-button>
                        <div>
                          {{
                            objActivity.wetherToRegister
                              ? "取消报名"
                              : "立即报名"
                          }}
                        </div>
                      </el-button>
                    </div>
                    <div
                      v-if="objActivity.tag == 'ONSITE_TEACHING'"
                      @click="applicationsUpImmediatelyIncident"
                      :class="['fl', 'applications']"
                    >
                      <el-button>
                        <div>团体报名</div>
                      </el-button>
                    </div>
                  </template>
                  <template
                    v-if="
                      objActivity.seats <= objActivity.holdUpSeats &&
                      !objActivity.wetherToRegister
                    "
                  >
                    <div
                      :class="[
                        'fl',
                        'immediatelys',
                        objActivity.wetherToRegister ? 'potActive' : '',
                      ]"
                    >
                      <el-button>
                        <div>报名名额已满</div>
                      </el-button>
                    </div>
                  </template>
                  <template
                    v-if="
                      objActivity.seats <= objActivity.holdUpSeats &&
                      objActivity.wetherToRegister
                    "
                  >
                    <div :class="['fl', 'btnApply', 'potActive']">
                      <el-button @click="signUpImmediatelyIncident">
                        <div>取消报名</div>
                      </el-button>
                    </div>
                  </template>
                </template>
              </template>
            </template>
            <template
              v-else-if="
                (!isCourseStartTime && isEndRegisterTime) ||
                (isCourseStartTime && isEndRegisterTime)
              "
            >
              <div
                :class="[
                  'fl',
                  'immediatelys',
                  objActivity.wetherToRegister ? 'potActive' : '',
                ]"
                @click="signUpImmediatelyIncident"
              >
                <el-button>报名已截止</el-button>
              </div>
            </template>
            <template v-else-if="isStartRegister">
              <div
                @click="signUpImmediatelyIncident"
                style="width: 240px"
                :class="[
                  'fl',
                  'immediatelys',
                  objActivity.wetherToRegister ? 'potActive' : '',
                ]"
              >
                <el-button>
                  <div>
                    {{
                      objActivity.startRegisterTime
                        | timeFormatter("{month}月{day}日 {hour}:{minute}")
                    }}
                    开始报名
                  </div>
                </el-button>
              </div>
            </template>
            <div class="pat">
              <div class="clear frApply">
                <div class="fl left">
                  <span
                    :class="['collection', isStore ? 'active' : '']"
                    @click.stop="attentionIncident('collection')"
                  ></span>
                  <span class="fr-text">{{ sumStore }}</span>
                </div>
                <div class="fl">
                  <span
                    :class="['zan', isLike ? 'active' : '']"
                    @click="attentionIncident('zan')"
                  ></span>
                  <span class="fr-text">{{ sumLike }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="mapVisible">
      <Map
        :lng="objActivity.activityPlaceLongitude"
        :lat="objActivity.activityPlaceLatitude"
        :mapVisible="mapVisible"
        @beforClose="
          () => {
            mapVisible = false;
          }
        "
      ></Map>
    </div>
    <div class="container-fluid details">
      <DetailsDirectory
        :detaIndex="detaIndex"
        :activityIntroduce="objActivity.activityIntroduce"
        :wetherToRegister="objActivity.wetherToRegister"
        :courseCatalogueList="courseCatalogueList"
        :courseWareList="courseWareList"
        :type="objActivity.tag"
        :signUpSuccess="signUpSuccess"
        :activityId="activityId"
        @detaIncident="detaIncident"
        @courseCatalogueIncident="courseCatalogueIncident"
        @signUpImmediatelyIncident="signUpImmediatelyIncident"
      ></DetailsDirectory>
    </div>
    <!-- 立即报名 -->
    <PopupReport
      ref="popupReport"
      :dialogVisible="dialogVisible"
      @beforeClose="
        () => {
          dialogVisible = false;
        }
      "
      @affirmIncidentApply="affirmIncidentApply"
      :type="objActivity.tag"
      :endRegisterTime="
        objActivity.endRegisterTime
          | timeFormatter('{year}年{month}月{day}日 {hour}:{minute}:{second}')
      "
      :courseStartTime="courseStartTimeAdnCourseEndTime"
      :activityId="activityId"
      :activityName="objActivity.activityName"
    >
    </PopupReport>
    <!-- 评价 -->
    <template v-if="popupTableVisible">
      <PopupTable
        ref="model"
        :dialogVisible="popupTableVisible"
        @beforeClose="
          () => {
            popupTableVisible = true;
          }
        "
        @beforeDelete="
          () => {
            popupTableVisible = false;
          }
        "
        :activityId="activityId"
        :activityName="objActivity.activityName"
        @submitIncident="submitIncident"
      >
      </PopupTable>
    </template>
    <!-- 团体报名 -->
    <template v-if="applications">
      <PopupApplications
        :applications.sync="applications"
        :type="objActivity.tag"
        :endRegisterTime="
          objActivity.endRegisterTime
            | timeFormatter('{year}年{month}月{day}日 {hour}:{minute}:{second}')
        "
        :courseStartTime="courseStartTimeAdnCourseEndTime"
        :activityId="activityId"
        :activityName="objActivity.activityName"
      >
      </PopupApplications>
    </template>
  </div>
</template>
<script>
import Vue from 'vue';
import { Rate } from 'element-ui';
Vue.use(Rate);
import Utils from '@/utils';
import DetailsDirectory from './../components/details-directory';
import PopupReport from './../components/popup-report';
import PopupApplications from '@/components/PopupApplications/index';
import Xgplayer from '@/components/xgplayer/index';
import Map from '@/components/Map/index';
import PopupTable from '@/views/learn/components/popup-table';
import { mapGetters } from 'vuex';
import { pupopStatus } from '@/config/pupupUtils';
import { course_classification } from '@/config/statusCode';
import { timeFormatter } from '@/utils/verify';
import { postMyCourseEvaluation } from '@/api/learn';
import {
  homePageActivityDetail,
  homePageCourseDetailAvgScore,
  activityDetailCollect,
  activityDetailLike,
  homePageActivityDetailDirecory,
  homePageCourseDetailActivityCourseDetail,
  activityDetailCourseTeacher,
  myCourseCancelRegister,
  activityDetailCheckActivityTime,
  haveOpenId,
  myCourseWare
} from '@/api/api';
export default {
  name: 'CourseDetails',
  filters: { timeFormatter, course_classification },
  components: {
    DetailsDirectory,
    PopupReport,
    Xgplayer,
    Map,
    PopupTable,
    PopupApplications
  },
  data() {
    return {
      signUpSuccess: false, // 默认选中
      objActivity: {},
      courseStartmathMinEndTime: '', // 面授培训时间
      courseStartTimeAdnCourseEndTime: '', // 开课时间 ~ 课程结束时间
      sumStore: 0,
      sumLike: 0,
      isStore: false, // 是否收藏 ,
      isLike: false, // 是否点赞 ,
      activityId: 0,
      dialogVisible: false, // 报名弹出窗口
      detaIndex: 0,
      courseCatalogueList: [], // 课程目录
      courseWareList: [], // 课件目录下载
      disableProgress: true, // 录播进度条是否拖动
      disableTitle: '', // 是否可以拖
      proTime: 0, // 播放的时间
      proActivityCourseId: '', // 视频轮询ID
      courseVideoUrl: '',
      activityCourseId: '',
      cancelRegisterEndTimeLave: '', // 直播取消报名时间转换
      courseEndTime: '', // 课程结束时间
      isActivityId: false, // 防止多次报名
      value: 0, // 评价的分数
      colors: ['#FF5102', '#FF5102', '#FF5102'],
      mapVisible: false, // 地图
      isRegisterByRoster: '', // 是否有花名册
      popupTableVisible: false,
      isStartRegister: false, // 可开始报名
      isCourseStartTime: false, // 可取消报名
      isEndRegisterTime: false, // 可报名
      applications: false, // 团体报名
      isWetherToRegister: false // 报名后还是去团体报名
      // activityStartTime: '',                  // 活动开始时间
      // activityEndTime: '',                    // 活动结束时间 ,
      // endRegisterTime: '',                    // 结束报名时间
      // cancelRegisterEndTime: '',              // 取消报名时间转换
      // courseStartTime: '',                    // 开课时间
      // startRegisterTime: '',                  // 开始报名时间
    };
  },
  computed: {
    ...mapGetters(['consumType', 'userPhone']),
    isGenerationApply() {
      return this.$store.state.isGenerationApply;
    }
  },
  created() {
    this.init();
    this.$nextTick(() => {
      if (this.isGenerationApply) {
        this.applications = true;
      }
    });
  },
  methods: {
    init() {
      const { id, path } = this.$route.query;
      if (path == '/agreement' && !this.consumType) {
        this.$store.commit('isChecked', false);
        this.$qrCode().then((res) => {
          this.getHomePageActivityDetail();
        });
      }
      this.activityId = String(id);
      this.getHomePageActivityDetail();
      homePageCourseDetailAvgScore({ activityId: this.activityId }).then(
        (r) => {
          if (r.code == 200) {
            if (r.data == null) {
              this.value = 0;
              return;
            }
            const value = r.data.toFixed(1);
            this.value = parseFloat(value);
          }
        }
      );
    },
    // 检查是否关注公众号提醒
    haveOpen() {
      haveOpenId({}).then((res) => {
        if (res.code === 200) {
          if (!res.data) {
            this.$subscribePopup().then((res) => {});
          }
        }
      });
    },
    // 获取活动详情
    getHomePageActivityDetail() {
      const _this = this;
      const jsonData = {
        activityId: this.activityId
      };
      homePageActivityDetail(jsonData).then((res) => {
        if (res.code == 200) {
          if (
            new Date(res.data['courseEndTime']).getDate() ==
            new Date(res.data['courseStartTime']).getDate()
          ) {
            _this.courseStartTimeAdnCourseEndTime =
              timeFormatter(
                res.data['courseStartTime'],
                '{year}年{month}月{day}日 {hour}:{minute}:{second}'
              ) +
              '~' +
              timeFormatter(
                res.data['courseEndTime'],
                '{hour}:{minute}:{second}'
              );
          } else {
            _this.courseStartTimeAdnCourseEndTime =
              timeFormatter(
                res.data['courseStartTime'],
                '{year}年{month}月{day}日 {hour}:{minute}:{second}'
              ) +
              '~' +
              timeFormatter(
                res.data['courseEndTime'],
                '{month}月{day}日 {hour}:{minute}:{second}'
              );
          }
          _this.objActivity = res.data;
          _this.sumStore = res.data.map.sumStore;
          _this.sumLike = res.data.map.sumLike;
          _this.isStore = res.data.isStore;
          _this.isLike = res.data.isLike;
          _this.activityCourseId = String(res.data.activityCourseId);
          // 报名截止
          if (new Date().getTime() > res.data['endRegisterTime']) {
            _this.isEndRegisterTime = true;
          } else {
            _this.isEndRegisterTime = false;
          }
          // 取消报名结束
          if (res.data['isNeedCancelRegisterTime']) {
            if (res.data['cancelRegisterEndTime'] == null) {
              _this.isCourseStartTime = true;
            } else {
              if (new Date().getTime() > res.data['cancelRegisterEndTime']) {
                _this.isCourseStartTime = true;
              } else {
                _this.isCourseStartTime = false;
              }
            }
          } else {
            _this.isCourseStartTime = true;
          }
          // 报名开始
          if (res.data['startRegisterTime'] > new Date().getTime()) {
            _this.isStartRegister = true;
          } else {
            _this.isStartRegister = false;
          }
          // 进入页面请求面试接口获取时间
          if (
            new Date(res.data['onsiteTeachingStartTime']).getDate() ==
            new Date(res.data['onsiteTeachingEndTime']).getDate()
          ) {
            this.courseStartmathMinEndTime = timeFormatter(
              res.data['onsiteTeachingStartTime'],
              '{year}年{month}月{day}日'
            );
          } else {
            this.courseStartmathMinEndTime =
              timeFormatter(
                res.data['onsiteTeachingStartTime'],
                '{year}年{month}月{day}日'
              ) +
              ' ~ ' +
              timeFormatter(
                res.data['onsiteTeachingEndTime'],
                '{month}月{day}日'
              );
          }
        }
      });
    },
    // 1、collection 收藏 2、zan 点赞
    attentionIncident(method) {
      const jsonData = {
        activityId: this.activityId
      };
      if (this.consumType) {
        if (!this.objActivity.wetherToRegister) {
          Utils.msgWarning(
            `请先报名后，再${method == 'collection' ? '添加收藏' : '点赞关注'}`
          );
          return;
        }
        switch (method) {
          case 'collection':
            activityDetailCollect(jsonData).then((res) => {
              if (res.code == 200) {
                this.getHomePageActivityDetail();
                if (!this.isStore) {
                  Utils.msgSuccess('收藏成功');
                } else {
                  Utils.msgSuccess('取消收藏成功');
                }
              }
            });
            break;
          case 'zan':
            activityDetailLike(jsonData).then((res) => {
              if (res.code == 200) {
                this.getHomePageActivityDetail();
                if (!this.isLike) {
                  Utils.msgSuccess('点赞成功');
                } else {
                  Utils.msgSuccess('取消点赞成功');
                }
              }
            });
            break;
          default:
            break;
        }
      } else {
        this.login();
      }
    },
    // 录播课程
    allHomePageCourseDetailActivityCourseDetail(itme, type) {
      homePageCourseDetailActivityCourseDetail({
        activityCourseId: itme.activityCourseId
      }).then((res) => {
        if (res.code == 200) {
          if (res.data.state == 'NOT_WATCH') {
            this.disableProgress = true;
            this.disableTitle = 'NOT_WATCH';
          } else {
            this.disableProgress = false;
            this.disableTitle = 'WATCH';
          }
          this.proTime = parseInt(res.data.time);
          this.proActivityCourseId = String(res.data.activityCourseId);
          if (type == 'pullStreamUrl') {
            this.courseVideoUrl = itme.pullStreamUrl;
          } else {
            this.courseVideoUrl = itme.courseVideoUrl;
          }
          this.activityCourseId = itme.activityCourseId;
        }
      });
    },
    // 去登录
    login() {
      const obj = {
        title: pupopStatus(1)[1],
        leftBtn: pupopStatus(2)[0],
        rightBtn: pupopStatus(3)[0]
      };
      this.$trainPopup(obj).then((res) => {
        if (res.code == 200) {
          this.$qrCode().then((res) => {
            this.getHomePageActivityDetail();
            // 目录更新获取到相关视频
            this.getHomePageActivityDetailDirecory();
          });
        }
      });
    },
    // 取消报名弹出窗口
    registration() {
      const obj = {
        title: pupopStatus(1)[3],
        leftBtn: pupopStatus(2)[0],
        rightBtn: pupopStatus(3)[0]
      };
      this.$trainPopup(obj).then((res) => {
        if (res.code == 200) {
          myCourseCancelRegister({ activityId: this.activityId }).then(
            (res) => {
              if (res.code == 200) {
                this.$loading();
                setTimeout(() => {
                  this.$loading().close();
                  this.dialogLogin = false;
                  this.courseVideoUrl = false;
                  this.signUpSuccess = false;
                  Utils.msgSuccess('取消报名成功');
                  this.getHomePageActivityDetail();
                }, 1500);
              }
            }
          );
        }
      });
    },
    // 目录按钮
    detaIncident(index) {
      if (index == 1) {
        this.getHomePageActivityDetailDirecory();
      }
      if (index == 2) {
        myCourseWare({
          activityId: this.activityId,
          pageSize: 1000,
          pageNum: 1
        }).then((r) => {
          if (r.code == 200) {
            const { list } = r.data;
            list.forEach((val) => {
              val.loading = false;
            });
            this.courseWareList = list;
          }
        });
      }
      this.detaIndex = index;
    },
    // 目录列表
    getHomePageActivityDetailDirecory() {
      const jsonData = {
        activityId: this.activityId
      };
      homePageActivityDetailDirecory(jsonData).then((res) => {
        if (res.code == 200) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['courseStartTimes'] = timeFormatter(
              res.data[i]['courseStartTime'],
              '{year}年{month}月{day}日'
            );
            if (
              new Date(res.data[i]['courseEndTime']).getDate() ==
              new Date(res.data[i]['courseStartTime']).getDate()
            ) {
              res.data[i]['courseStartTimes'] =
                timeFormatter(
                  res.data[i]['courseStartTime'],
                  '{month}月{day}日 {hour}:{minute}:{second}'
                ) +
                '~' +
                timeFormatter(
                  res.data[i]['courseEndTime'],
                  '{hour}:{minute}:{second}'
                );
            } else {
              res.data[i]['courseStartTimes'] =
                timeFormatter(
                  res.data[i]['courseStartTime'],
                  '{month}月{day}日 {hour}:{minute}:{second}'
                ) +
                '~' +
                timeFormatter(
                  res.data[i]['courseEndTime'],
                  '{month}月{day}日 {hour}:{minute}:{second}'
                );
            }
          }
          // 最小时间和最大时间范围
          this.courseCatalogueList = res.data;
        }
      });
    },
    putPeriodPopup(title) {
      const obj = {
        title: title,
        btnText: '知道了'
      };
      this.$periodPopup(obj).then(() => {});
    },
    // 报名公共提示信息
    allcourseCatalogue() {
      const _this = this;
      if (!_this.consumType) {
        _this.login();
        return false;
      }
      if (!_this.userPhone) {
        _this.$phonePopup((res) => {});
        return false;
      }
      if (_this.objActivity.wetherToRegister && !this.isWetherToRegister) {
        if (_this.objActivity.isAccomplish) {
          this.putPeriodPopup('您已完成课程学习，无法取消报名');
          return false;
        }
        if (_this.isCourseStartTime) {
          this.putPeriodPopup(
            '抱歉，取消报名截止时间已过，该课程已不能取消，请合理安排时间进行学习'
          );
          return false;
        }
      }
      if (_this.isEndRegisterTime) {
        Utils.msgWarning('报名已截止');
        return false;
      }
      if (_this.isStartRegister) {
        Utils.msgWarning('报名时间未开始');
        return false;
      }
      return true;
    },
    // 点击课程时
    courseCatalogueIncident(itme) {
      const _this = this;
      if (!_this.consumType) {
        _this.login();
        return;
      }
      if (_this.isEndRegisterTime && !_this.objActivity.wetherToRegister) {
        Utils.msgWarning('报名已截止');
        return;
      }
      if (_this.isStartRegister && !_this.objActivity.wetherToRegister) {
        Utils.msgWarning('报名时间未开始');
        return;
      }
      // 面授不限制人数
      if (
        _this.objActivity.tag == 'ONSITE_TEACHING' &&
        !_this.objActivity.wetherToRegister
      ) {
        _this.dialogVisible = true;
        return;
      }
      // 有花名册不限制人数
      if (
        _this.objActivity.isRegisterByRoster &&
        !_this.objActivity.wetherToRegister
      ) {
        _this.dialogVisible = true;
        return;
      }
      // 大于0 可以报名
      if (!_this.objActivity.isOpen) {
        if (
          parseInt(_this.objActivity.seats) - _this.objActivity.holdUpSeats >
            0 &&
          !_this.objActivity.wetherToRegister
        ) {
          if (_this.objActivity.tag == 'LIVE_BROADCAST') {
            this.getActivityDetailCheckActivityTime();
            return;
          } else {
            _this.dialogVisible = true;
            return;
          }
        } else if (
          parseInt(_this.objActivity.seats) - _this.objActivity.holdUpSeats <=
            0 &&
          !_this.objActivity.wetherToRegister
        ) {
          Utils.msgWarning('报名人数已满~');
          return;
        }
      } else {
        // 不限制名额的情况下
        if (!_this.objActivity.wetherToRegister) {
          if (_this.objActivity.tag == 'LIVE_BROADCAST') {
            this.getActivityDetailCheckActivityTime();
          } else {
            _this.dialogVisible = true;
          }
          return;
        }
      }
      if (_this.objActivity.tag == 'LIVE_BROADCAST') {
        if (itme.courseState == 'RUNNING') {
          _this.$router.push({
            path: '/broadcast',
            query: {
              activityCourseId: String(itme.activityCourseId),
              url: itme.pullStreamUrl,
              courseStartTime: String(itme.courseStartTime),
              activityName: itme.courseName,
              courseState: itme.courseState
            }
          });
          return;
        } else if (
          itme.courseState == 'OVER' ||
          itme.courseState == 'PLAYBACK'
        ) {
          _this.$router.push({
            path: '/broadxgplayer',
            query: {
              activityCourseId: String(itme.activityCourseId),
              courseVideoUrl: itme.pullStreamUrl,
              courseStartTime: String(itme.courseStartTime),
              activityName: itme.courseName,
              courseState: itme.courseState,
              imageUrl: _this.objActivity.imgUrl
            }
          });
          return;
        } else {
          // 未开始的状态
          Utils.msgWarning('直播准备中~');
          return;
        }
      } else if (_this.objActivity.tag == 'UPLOAD_VIDEO') {
        _this.courseVideoUrl = '';
        _this.allHomePageCourseDetailActivityCourseDetail(itme, '');
      } else {
        // console.log('面授课')
      }
    },
    // 团体报名
    applicationsUpImmediatelyIncident() {
      const _this = this;
      _this.isWetherToRegister = true;
      if (!_this.allcourseCatalogue()) return;
      this.applications = true;
    },
    // 立即报名
    signUpImmediatelyIncident() {
      const _this = this;
      if (!_this.allcourseCatalogue()) return;
      if (_this.objActivity.tag == 'ONSITE_TEACHING') {
        if (!_this.objActivity.wetherToRegister) {
          _this.dialogVisible = true;
        } else {
          _this.registration();
        }
        return;
      }
      // 有花名册不限制人数
      if (_this.objActivity.isRegisterByRoster) {
        if (!_this.objActivity.wetherToRegister) {
          _this.dialogVisible = true;
        } else {
          _this.registration();
        }
        return;
      }
      if (!_this.objActivity.isOpen) {
        if (
          parseInt(_this.objActivity.seats) - _this.objActivity.holdUpSeats >
            0 &&
          !_this.objActivity.wetherToRegister
        ) {
          if (_this.objActivity.tag == 'LIVE_BROADCAST') {
            this.getActivityDetailCheckActivityTime();
            return;
          } else {
            _this.dialogVisible = true;
            return;
          }
        } else if (
          parseInt(_this.objActivity.seats) - _this.objActivity.holdUpSeats <=
            0 &&
          !_this.objActivity.wetherToRegister
        ) {
          Utils.msgWarning('报名人数已满~');
          return;
        }
        if (_this.objActivity.wetherToRegister) {
          _this.registration();
          return;
        }
      } else {
        if (!_this.objActivity.wetherToRegister) {
          if (_this.objActivity.tag == 'LIVE_BROADCAST') {
            this.getActivityDetailCheckActivityTime();
            return;
          } else {
            _this.dialogVisible = true;
            return;
          }
        } else {
          _this.registration();
          return;
        }
      }
    },
    // 直播检查是否存在同时间段的报名信息
    getActivityDetailCheckActivityTime() {
      activityDetailCheckActivityTime({ activityId: this.activityId }).then(
        (r) => {
          if (r.code == 200) {
            if (r.data) {
              const obj = {
                title: pupopStatus(4)[0],
                leftBtn: pupopStatus(2)[0],
                rightBtn: pupopStatus(3)[0]
              };
              this.$trainPopup(obj).then((res) => {
                this.dialogVisible = true;
              });
            } else {
              this.dialogVisible = true;
            }
          }
        }
      );
    },
    // 确认报名
    affirmIncidentApply(e) {
      const _this = this;
      const obj = {};
      if (_this.isActivityId) return;
      _this.isActivityId = true;
      activityDetailCourseTeacher(e)
        .then((res) => {
          _this.$refs.popupReport.loadingIncident();
          if (res.code == 200) {
            // 报名成功
            _this.dialogVisible = false;
            _this.isActivityId = false;
            _this.getHomePageActivityDetail();
            _this.detaIndex = 1;
            _this.signUpSuccess = true;
            _this.getHomePageActivityDetailDirecory();
            const obj = {
              title: '',
              headline: '报名成功！',
              type: _this.objActivity.tag,
              link: ''
            };
            switch (_this.objActivity.tag) {
              case 'UPLOAD_VIDEO':
                _this.courseVideoUrl = '';
                const jsonData = {
                  activityId: _this.activityId
                };
                obj.title = pupopStatus(5)[0];
                _this.$periodPopup(obj).then(() => {
                  _this.haveOpen();
                });
                homePageActivityDetailDirecory(jsonData).then((res0) => {
                  if (res0.code == 200) {
                    homePageCourseDetailActivityCourseDetail({
                      activityCourseId: res0.data[0].activityCourseId
                    }).then((res1) => {
                      if (res1.code == 200) {
                        if (res1.data.state == 'NOT_WATCH') {
                          // 不能拖
                          _this.disableProgress = true;
                          _this.disableTitle = 'NOT_WATCH';
                        } else {
                          // WATCH 能拖
                          _this.disableProgress = false;
                          _this.disableTitle = 'WATCH';
                        }
                        _this.proTime = parseInt(res1.data.time);
                        _this.proActivityCourseId = String(
                          res1.data.activityCourseId
                        );
                        _this.courseVideoUrl = res0.data[0].courseVideoUrl;
                        _this.activityCourseId = String(
                          res1.data.activityCourseId
                        );
                      }
                    });
                  }
                });
                break;
              case 'LIVE_BROADCAST':
                obj.title = pupopStatus(5)[1];
                _this.$periodPopup(obj).then(() => {
                  _this.haveOpen();
                });
                break;
              case 'ONSITE_TEACHING':
                obj.title = pupopStatus(5)[2];
                obj.link = _this.activityId;
                _this.$periodPopup(obj).then(() => {
                  _this.haveOpen();
                });
                break;
              default:
                break;
            }
          } else {
            // 报名失败，人数已满时请求接口显示数据明确
            _this.getHomePageActivityDetail();
            _this.isActivityId = false;
          }
        })
        .catch(() => {
          _this.$refs.popupReport.loadingIncident();
          _this.getHomePageActivityDetail();
        });
    },
    // （视频）关闭提示，刷新列表改变完成状态
    xgplayerIncident(e) {
      if (e.code == 200) {
        this.getHomePageActivityDetailDirecory();
        // 看完整门课程时去评价
        if (e.data.indexOf('已完成该活动') !== -1) {
          this.popupTableVisible = true;
        }
      }
    },
    // 提交评价
    submitIncident(e) {
      postMyCourseEvaluation(e)
        .then((res) => {
          this.$refs.model.loadingIncident();
          if (res.code == 200) {
            Utils.msgSuccess('评价成功');
            this.init();
            this.popupTableVisible = false;
          }
        })
        .catch(() => {
          this.$refs.modal.loadingIncident();
        });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~./index";
</style>
