<template>
  <div v-show="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      width="530px"
      :before-close="beforeClose"
    >
      <SignUp
        ref="popupReport"
        @affirmIncidentApply="affirmIncidentApply"
        :playType="type"
        :endRegisterTime="endRegisterTime"
        :courseStartTime="courseStartTime"
        :activityName="activityName"
        :activityId="activityId"
        :dialogVisible="dialogVisible"
      >
      </SignUp>
    </el-dialog>
  </div>
</template>
<script>
import SignUp from './sign-up';
export default {
  name: 'PopupReport',
  components: {
    SignUp
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    endRegisterTime: {
      type: String,
      default: ''
    },
    courseStartTime: {
      type: String,
      default: ''
    },
    activityName: {
      type: String,
      default: ''
    },
    activityId: {
      type: String,
      default: ''
    }
  },
  methods: {
    beforeClose() {
      this.$emit('beforeClose');
    },
    affirmIncidentApply(e) {
      this.$emit('affirmIncidentApply', e);
    },
    loadingIncident() {
      this.$refs.popupReport.loadingIncident();
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
/deep/.el-dialog {
  border-radius: 10px;
  height: auto;
}
/deep/.el-dialog__headerbtn {
  font-size: 20px;
  font-weight: bold;
}
/deep/.el-dialog__headerbtn /deep/.el-dialog__close:hover {
  color: @TEXT-COLOR-00BD92;
}
/deep/.el-dialog__headerbtn /deep/.el-dialog__close {
  color: @TEXT-COLOR-363636;
}
/deep/.el-dialog__body {
  padding: 10px 40px 40px 40px;
  box-sizing: border-box;
  color: @TEXT-COLOR-363636;
}
</style>

